import { FeatureFlagType } from '~/constants/featureFlagReleaseStatus';
import { localStorageKeys } from '~/constants/localStorageKeys';
import { useLocalStorage } from '~/hooks/common/useLocalStorage';

const INITIAL_FEATURES: FeatureFlagType[] = [];

export const useFeatureFlag = () => {
  const { value, loading } = useLocalStorage<FeatureFlagType[]>(
    localStorageKeys.development.featureFlags,
    INITIAL_FEATURES
  );

  return {
    state: {
      isActive: (feature: FeatureFlagType) => value.includes(feature),
      activeFeatures: value,
      loading,
    },
  };
};
