import { z } from 'zod';

export type EnvironmentVariable = z.infer<typeof envSchema>;

const envSchema = z.object({
  serverEnv: z.object({}),
  publicEnv: z.object({
    environment: z.enum(['production', 'staging', 'development', 'test', 'debug']),
    targetDomain: z.enum(['client', 'admin']),
    applicationMode: z.enum(['working', 'under_maintenance']),
    apiBaseUrl: z.string().url(),
    oldTimeeAppUrl: z.string().url(),
    gtmId: z.string().min(1),
    gtmAuth: z.string().min(1),
    gtmPreview: z.string().min(1),
  }),
});

export const validateEnv = envSchema.parse;

export const envManager = validateEnv({
  serverEnv: {},
  publicEnv: {
    environment: process.env.NEXT_PUBLIC_ENV,
    targetDomain: process.env.NEXT_PUBLIC_TARGET_DOMAIN,
    applicationMode: process.env.NEXT_PUBLIC_APPLICATION_MODE,
    apiBaseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
    oldTimeeAppUrl: process.env.NEXT_PUBLIC_OLD_APP_URL,
    gtmId: process.env.NEXT_PUBLIC_GTM_ID,
    gtmAuth: process.env.NEXT_PUBLIC_GTM_AUTH,
    gtmPreview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
  },
});
