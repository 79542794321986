import React, { createContext, useCallback, useContext, useState } from 'react';
import { ModalContentsContainer } from '~/components/containers/ModalContentsContainer';
import { useModal } from '~/hooks/common/useModal';

type ConfirmModalState = {
  title?: string;
  body?: React.ReactNode;
  applyText?: string;
  cancelText?: string;
};

type ConfirmModalAction = {
  setModalState?: (newVal: ConfirmModalState) => void;
  openModal?: () => void;
  setResolveModal?: (fn: (data: boolean) => void) => void;
};

export const ConfirmModalStateContext = createContext<ConfirmModalState>({});

export const ConfirmModalActionContext = createContext<ConfirmModalAction>({});

export const ConfirmModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [modalState, setModalState] = useState<ConfirmModalState>({});
  const [resolveModal, setResolveModal] = useState<{ call: (data: boolean) => void }>();

  const modal = useModal(
    <ModalContentsContainer
      title={modalState.title}
      sections={[
        {
          children: modalState.body,
        },
      ]}
      cancel={{
        message: modalState.cancelText || '閉じる',
        onClick: () => {
          resolveModal?.call?.(false);
          modal.close();
        },
      }}
      apply={{
        type: 'button',
        message: modalState.applyText || 'OK',
        onClick: () => {
          // NOTE: modal.close()を先に呼ぶとonCloseが呼ばれてfalseが返ってしまうので注意
          resolveModal?.call?.(true);
          modal.close();
        },
      }}
    />,
    {
      onClose: () => {
        resolveModal?.call?.(false);
      },
    }
  );

  return (
    <ConfirmModalStateContext.Provider value={{ ...modalState }}>
      <ConfirmModalActionContext.Provider
        value={{
          setModalState: setModalState,
          openModal: modal.open,
          setResolveModal: (fn) => {
            setResolveModal({ call: fn });
          },
        }}
      >
        {children}
        {modal.render()}
      </ConfirmModalActionContext.Provider>
    </ConfirmModalStateContext.Provider>
  );
};

export const useModalConfirm = () => {
  const { setModalState, openModal, setResolveModal } = useContext(ConfirmModalActionContext);

  const confirm = useCallback(
    (options: {
      title: string;
      body: React.ReactNode;
      cancelText?: string;
      applyText?: string;
    }): Promise<boolean> => {
      setModalState?.(options);

      return new Promise<boolean>((resolve) => {
        openModal?.();
        setResolveModal?.(resolve);
      });
    },
    [openModal, setResolveModal, setModalState]
  );

  return { confirm };
};
