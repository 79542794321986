import { useEffect, useCallback } from 'react';

export const useViewportTrimmer = (minWidth: number) => {
  const updateMetaViewport = useCallback(() => {
    const currentWidth = window.screen.width || window.outerWidth;
    const viewport = document.querySelector("meta[name='viewport']");
    if (viewport === null) return;

    viewport.setAttribute(
      'content',
      currentWidth < minWidth ? `width=${minWidth}` : 'width=device-width'
    );
  }, [minWidth]);

  useEffect(() => {
    window.addEventListener('resize', updateMetaViewport);
    window.addEventListener('orientationchange', updateMetaViewport);

    updateMetaViewport();

    return () => {
      window.removeEventListener('resize', updateMetaViewport);
      window.removeEventListener('orientationchange', updateMetaViewport);
    };
  }, [updateMetaViewport]);
};
