import React, { useState } from 'react';
import { textCss } from '~/styles/textCss';
import * as styles from './index.styles';

export type Props = {
  developmentEnvironment: 'staging' | 'development' | 'test' | 'debug';
  flagList: string[];
};

export const DevelopmentLabel: React.FC<Props> = (props) => {
  const [shown, setShown] = useState(true);

  const onClickHandler = () => {
    if (confirm('ラベルの表示を一時的に削除しますか?')) setShown(false);
  };

  return (
    <>
      {shown && (
        <div
          css={styles.container({ flagged: props.flagList.length > 0 })}
          onClick={onClickHandler}
        >
          <div>{props.developmentEnvironment}</div>
          <div css={textCss({ size: 'xs', weight: 'regular' })}>{props.flagList.join(', ')}</div>
        </div>
      )}
    </>
  );
};
