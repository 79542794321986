import React from 'react';
import { Global, SerializedStyles } from '@emotion/react';

type IUseStyles = (
  styles: SerializedStyles[]
) => {
  renderStyles: () => JSX.Element;
};

export const useStyles: IUseStyles = (styles: SerializedStyles[]) => {
  const renderResetStyles = () => (
    <>
      {styles.map((style, idx) => (
        <Global key={idx} styles={style} />
      ))}
    </>
  );
  return { renderStyles: renderResetStyles };
};
