import { detect, Browser } from 'detect-browser';

export type BrowserType = ReturnType<typeof detect>;

export const RecommendedBrowserLabels = [
  '最新版 Google Chrome',
  '最新版 Microsoft Edge',
  '最新版 Safari',
];

// NOTE: 以下から厳選
// https://github.com/DamonOehlman/detect-browser/blob/master/src/index.ts
const RECOMMENDED_BROWSERS: (Browser | 'node' | 'bot' | 'react-native')[] = [
  'edge-chromium',
  'chrome',
  'chromium-webview',
  'crios', // iOS Chrome
  'android',
  'ios',
  'safari',
  'ios-webview',
];
// 非推奨かつ2023/10以降にブロック予定のブラウザ
const BLOCK_BROWSERS: (Browser | 'node' | 'bot' | 'react-native')[] = ['ie'];

const BROWSERS: { [key: string]: { name: string; url?: string } } = {
  PC_EDGE: {
    name: '最新版のEdge',
    url: 'https://www.microsoft.com/ja-jp/edge',
  },
  PC_CHROME: {
    name: '最新版のChrome',
    url: 'https://www.google.com/chrome/',
  },
  PC_SAFARI: {
    name: '最新版のSafari',
  },
  MOBILE_CHROME: {
    name: '最新版のChrome',
    url: 'https://play.google.com/store/apps/details?id=com.android.chrome&hl=ja&gl=US',
  },
  MOBILE_SAFARI: {
    name: '最新版のSafari',
  },
};

const usingRecommendEnv = (browser: BrowserType): boolean => {
  if (browser === null) return true;
  return RECOMMENDED_BROWSERS.includes(browser.name);
};

const usingBlockEnv = (browser: BrowserType): boolean => {
  if (browser === null) return false;
  return BLOCK_BROWSERS.includes(browser.name);
};

const recommendBrowser = (browser: BrowserType): { name: string; url?: string } | undefined => {
  if (browser?.os === 'iOS') return BROWSERS.MOBILE_SAFARI;
  if (browser?.os === 'Android OS') return BROWSERS.MOBILE_CHROME;
  if (browser?.os?.match(/^Windows/)) return BROWSERS.PC_EDGE;
  if (browser?.os === 'Mac OS') return BROWSERS.PC_SAFARI;
  return undefined;
};

export const recommendBrowserMessage = (
  detectBrowser = detect
):
  | {
      usingRecommend: true;
    }
  | {
      usingRecommend: false;
      usingBlockBrowser: boolean;
      recommendBrowser: { name: string; url?: string } | undefined;
    } => {
  const browserInfo = detectBrowser();
  if (usingRecommendEnv(browserInfo)) return { usingRecommend: true };
  const recommendBrowserInfo = recommendBrowser(browserInfo);
  const usingBlockBrowser = usingBlockEnv(browserInfo);
  return {
    usingRecommend: false,
    usingBlockBrowser,
    recommendBrowser: recommendBrowserInfo,
  };
};
