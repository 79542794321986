var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"bd54b75f6ba46de21f2a61492a583133ed8d17fc"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { envManager } from './src/constants/envManager';
import { recommendBrowserMessage } from '~/libs/recommendBrowserMessage';

const status = recommendBrowserMessage();

Sentry.init({
  dsn: 'https://66e7447d907d46eaba8ebe7e8ce5506d@o247126.ingest.sentry.io/5568216',
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: envManager.publicEnv.environment,
  integrations: status.usingRecommend ? [new Sentry.Replay()] : [],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.1,
});
