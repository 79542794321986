import { useRouter } from 'next/router';
import { useEffect, useCallback } from 'react';
import { useKarteViewEvent } from '~/hooks/karte/useKarteViewEvent';

export const useRouteChangeCompleteListener = () => {
  const router = useRouter();
  const sendKarteViewEvent = useKarteViewEvent();
  const handleRouteChange = useCallback(() => {
    sendKarteViewEvent();
  }, [sendKarteViewEvent]);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [handleRouteChange, router.events, sendKarteViewEvent]);
};
