import { useRouter } from 'next/router';
import { useEffect } from 'react';

// NOTE: NextHistoryStateがexportされていないので同じものを記述
interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
}
interface NextHistoryState {
  url: string;
  as: string;
  options: TransitionOptions;
}
type BeforePopStateHandler = (state: NextHistoryState) => boolean;

const globalBeforePopStateHandlers: { [key: string]: BeforePopStateHandler } = {};

export const addBeforePopStateHandler = (key: string, handler: BeforePopStateHandler) => {
  globalBeforePopStateHandlers[key] = handler;
};

export const removeBeforePopStateHandler = (key: string) => {
  delete globalBeforePopStateHandlers[key];
};

export const useBeforePopStateHandlers = () => {
  const router = useRouter();

  useEffect(() => {
    router.beforePopState((...args) => {
      for (const handler of Object.values(globalBeforePopStateHandlers)) {
        if (!handler(...args)) {
          return false;
        }
      }

      return true;
    });
  });
};
