import React, { createContext, useContext, useState } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { Message, MessageHandler } from '~/types/message';
import useInterval from 'use-interval';
import { toastContainer } from './toastContainer';
import { omitMessages } from './omitMessages';
import { toastContent } from './toastContent';
import { matchMedia } from '~/styles/matchMedia';

export type Option = Partial<{
  onClick: () => void;
  autoClose: number | false;
}>;

// State, Action
export type ToastMessagesState = {
  messages: Message[];
};

export type ToastMessagesActions = {
  pushMessage: MessageHandler<Option>;
  pushBundleDisplayedMessage: MessageHandler<Option>; // 同一メッセージをまとめて表示するオプション
  clearMessage: () => void;
};

// Context type
export type ToastMessagesContext = {
  state: ToastMessagesState;
  actions: ToastMessagesActions;
};

const defaultValue: ToastMessagesContext = {
  state: {
    messages: [],
  },
  actions: {
    pushMessage: () => {},
    pushBundleDisplayedMessage: () => {},
    clearMessage: () => {},
  },
};

const ToastMessagesContext = createContext<ToastMessagesContext>(defaultValue);

const AUTO_CLOSE_DEFAULT_VALUE = 5_000;

export const ToastMessagesProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const [messages, setMessages] = useState<(Message & Option)[]>([]);

  const pushBundleDisplayedMessage = (newMessage: Message, option?: Option) => {
    setMessages((pastMessages) => {
      const newMessages = [...pastMessages, { ...newMessage, ...option }];
      return newMessages;
    });
  };

  const clearMessages = (): void => {
    setMessages([]);
  };

  const commonToastConfig: ToastOptions = {
    position: matchMedia.isMobile() ? 'top-center' : 'top-right',
  };

  const showMessage = (message: Message, option?: Option): void => {
    toast[message.type](toastContent(message), {
      ...commonToastConfig,
      onClick: option?.onClick,
      autoClose: option?.autoClose === undefined ? AUTO_CLOSE_DEFAULT_VALUE : option?.autoClose,
    });
  };

  const clearMessage = () => {
    toast.dismiss();
  };

  useInterval(() => {
    if (messages.length > 0) {
      const omittedMessages = omitMessages(messages);
      clearMessages();

      omittedMessages.forEach(({ onClick, autoClose, ...message }) => {
        showMessage(message, { onClick, autoClose });
      });
    }
  }, 1000);

  return (
    <ToastMessagesContext.Provider
      value={{
        state: {
          messages,
        },
        actions: {
          pushMessage: showMessage,
          pushBundleDisplayedMessage,
          clearMessage,
        },
      }}
    >
      {props.children}
      {toastContainer}
    </ToastMessagesContext.Provider>
  );
};

export const useToastMessages = () => useContext(ToastMessagesContext);
