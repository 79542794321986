import { css } from '@emotion/react';

export const unsupportedBrowser = {
  container: css`
    line-height: 24px;
    display: flex;
    flex-direction: column;
  `,
  recommendBrowser: css`
    list-style-type: disc;
  `,
};
