import React, { Fragment, useEffect } from 'react';
import Head from 'next/head';

type IUseChatbot = (options: {
  show: boolean;
}) => {
  render: () => React.ReactNode;
};

export const useChatbot: IUseChatbot = (option) => {
  const render = () => (
    <Fragment>
      {option.show && (
        <Head>
          <script
            async
            defer
            dangerouslySetInnerHTML={{
              __html: `window.aimWidgetInitialConfigs={projectId:"Pbq3ihlsn0p563reu1g4g",tenantId:"timee-cbscy",tenantName:"timee",type:"floating"};`,
            }}
          />
          <script async defer src="https://w.ai-messenger-static.app/aim.js"></script>,
        </Head>
      )}
    </Fragment>
  );

  useEffect(() => {
    const elements = document.querySelectorAll("[id^='aim__aim-widget-root']");
    for (let idx = 0; idx < elements.length; idx++) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (elements[idx] as any).style.display = option.show ? 'block' : 'none';
    }
  }, [option.show]);

  return {
    render: render,
  };
};
