import React from 'react';
import { SerializedStyles } from '@emotion/react';
import * as styles from './index.styles';

export type Props = {
  words: React.ReactNode[];
  customCss?: SerializedStyles;
};

export const TESTID = {} as const;

export const MESSAGE = {} as const;

export const SplittedPhrase: React.FC<Props> = (props: Props) => {
  return (
    <div css={props.customCss}>
      {props.words.map((word, i) => (
        <span css={styles.word} key={i}>
          {word}
        </span>
      ))}
    </div>
  );
};
