export const externalLinks = {
  lineContact: 'https://line.me/R/ti/p/%40zit3128f',
  contactForm: 'https://timee.co.jp/contact/',
  guidebook: 'https://timee.co.jp/0cpep8eaxe24ok0y/',
  termsOfUse:
    'https://lp.timee.co.jp/hubfs/Timee%E4%BA%8B%E6%A5%AD%E8%80%85%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84.pdf',
  faq:
    'https://help.timee.co.jp/ja/knowledge/%E4%BA%8B%E6%A5%AD%E4%B8%BB%E6%A7%98#%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F',
  troubleshooting:
    'https://help.timee.co.jp/ja/knowledge/%E7%AE%A1%E7%90%86%E7%94%BB%E9%9D%A2%E3%81%AE%E4%B8%8D%E5%85%B7%E5%90%88',
  aboutNpKakebarai:
    'https://help.timee.co.jp/ja/knowledge/-np%E3%81%8B%E3%81%91%E6%89%95%E3%81%84%E3%82%B5-%E3%83%93%E3%82%B9-%E3%81%B8%E3%81%AE%E7%99%BB%E9%8C%B2%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6',
  clientPageTroubleshooting:
    'https://help.timee.co.jp/ja/knowledge/%E7%AE%A1%E7%90%86%E7%94%BB%E9%9D%A2%E3%81%AE%E4%B8%8D%E5%85%B7%E5%90%88',
  zendeskContact: 'https://taimee.zendesk.com/hc/ja/requests/new',
  inquiryAboutLogin: 'https://taimee.zendesk.com/hc/ja/requests/new',
  unsupportedBrowser:
    'https://clients-help.timee.co.jp/hc/ja/articles/19903047779609-%E4%BA%8B%E6%A5%AD%E8%80%85%E6%A7%98%E5%90%91%E3%81%91%E7%AE%A1%E7%90%86%E7%94%BB%E9%9D%A2%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6-Internet-Explorer%E3%81%A7%E3%81%AE%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%82%92%E7%B5%82%E4%BA%86%E3%81%97%E3%81%BE%E3%81%99',
} as const;
