import { Message, MessageType } from '~/types/message';
import { uniqSort } from '~/utils/uniqSort';

type MessageWithOnClick = Message & {
  onClick?: () => void;
  autoClose?: number | false;
};

const TYPES: MessageType[] = ['error', 'success', 'info', 'warning'];

const compareMessageType = (a: Message, b: Message) =>
  TYPES.indexOf(a.type) - TYPES.indexOf(b.type);

const compareMessageBody = (a: Message, b: Message) => {
  if (a.body.length !== b.body.length) return b.body.length - a.body.length;

  for (let i = 0; i < a.body.length; i++) {
    const comp = a.body[i].localeCompare(b.body[i]);
    if (comp !== 0) return comp;
  }
  return 0;
};

const compare = (a: Message, b: Message) => {
  // type の比較で差がなければ、body の比較を行う
  const compareTypeResult = compareMessageType(a, b);
  return compareTypeResult === 0 ? compareMessageBody(a, b) : compareTypeResult;
};

// 同一 type、body のメッセージは、 onClick の一致不一致に関わらず同一とみなして uniqSort する
const mergeWithOnClickMessage = (message: MessageWithOnClick[]) =>
  uniqSort(
    message.filter((message) => message.onClick !== undefined),
    compare
  );

// type ごとにメッセージをまとめる
const mergeWithoutOnClickMessage = (messages: MessageWithOnClick[]): MessageWithOnClick[] => {
  const mergedMessage = messages
    .filter((message) => message.onClick === undefined)
    .reduce(
      (acc, message) => ({
        ...acc,
        [message.type]: (acc[message.type] || []).concat(message.body),
      }),
      {} as { [Key in MessageType]?: string[] }
    );
  return Object.entries(mergedMessage)
    .map(([messageType, body]) => ({
      type: messageType as MessageType,
      body: uniqSort(body, (a, b) => a.localeCompare(b)),
    }))
    .filter((message) => message.body.length !== 0);
};

export const omitMessages = (messages: MessageWithOnClick[]): MessageWithOnClick[] =>
  mergeWithoutOnClickMessage(messages).concat(mergeWithOnClickMessage(messages));
