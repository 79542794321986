/**
 * Do not edit directly
 * Generated on Thu, 14 Dec 2023 07:54:31 GMT
 */

export const componentTokens = {
  background: {
    alertMessageBox: '#FFF8F8',
    avatar: {
      block: '#DF3623',
    },
    badge: {
      done: '#EAEAEA',
      error: '#FFE1DF',
      new: '#DF3623',
      notice: '#FFF4A3',
      number: '#DF3623',
      progress: '#FFFFFF',
      rating: '#FFD700',
      review: {
        bad: '#FFE1DF',
        good: '#CDF7D1',
      },
      success: '#CDF7D1',
      unread: '#FF3B30',
      working: '#C8EDFF',
    },
    blockBrowserMessage: {
      error: '#FFE1DF',
    },
    button: {
      disabled: '#C7C7CC',
      primary: {
        default: '#007AFF',
        hover: '#0049BF',
      },
      secondary: {
        default: '#FFFFFF',
        hover: '#F1F1F1',
        selected: '#EFFBFF',
      },
    },
    calendar: {
      confirmed: '#CDF7D1',
      done: '#F1F1F1',
      reviewCandidate: '#FFF4A3',
      today: '#007AFF',
      wanted: '#DF3623',
    },
    carousel: {
      dotIndicator: {
        default: '#ffffff4d',
        selected: '#FFFFFF',
      },
      overlay: {
        hover: '#00000033',
      },
      slider: {
        default: '#ffffffcc',
        hover: '#FFFFFF',
      },
    },
    check: {
      default: {
        true: '#007AFF',
      },
      disabled: {
        true: '#EAEAEA',
      },
      false: '#FFFFFF',
    },
    checkContainer: {
      default: '#FFFFFF',
      disabled: '#FBFBFB',
    },
    circleRadioButton: {
      default: {
        true: '#007AFF',
      },
      disabled: {
        true: '#EAEAEA',
      },
      false: '#FFFFFF',
    },
    contentsArea: '#FBFBFB',
    draggableInputImages: {
      default: '#FFFFFF',
      error: '#FFF8F8',
      hover: '#EFFBFF',
    },
    draggableInputPdfs: {
      default: '#FFFFFF',
      error: '#FFF8F8',
      hover: '#EFFBFF',
      uploaded: '#FFFFFF',
    },
    icon: {
      error: '#DF3623',
      fileUploader: '#F1F1F1',
      information: '#007AFF',
      success: '#21972F',
      warning: '#FFD700',
    },
    input: {
      default: '#FFFFFF',
      disabled: '#FBFBFB',
      error: '#FFF8F8',
    },
    inputTime: {
      default: '#FFFFFF',
      disabled: '#FBFBFB',
      error: '#FFF8F8',
    },
    labelDesc: {
      primary: '#DF3623',
      secondary: '#F29300',
      tertiary: '#808080',
    },
    messageBox: {
      error: '#FFF8F8',
      information: '#FFFFFF',
      success: '#EFFBFF',
      warning: '#FFFFEA',
    },
    messageThread: {
      client: '#007AFF',
      worker: '#EAEAEA',
    },
    modalOverlay: '#00000080',
    pagination: {
      hover: '#EAEAEA',
      selected: '#343434',
    },
    panels: {
      accent: '#F1F1F1',
      brand: '#FFD700',
      normal: '#FFFFFF',
    },
    recommendBrowserMessage: {
      warning: '#FFF4A3',
    },
    segmentedControl: {
      default: '#FFFFFF',
      hover: '#F1F1F1',
      selected: '#EFFBFF',
    },
    selectDropdown: {
      default: '#FFFFFF',
      disabled: '#FBFBFB',
      error: '#FFF8F8',
    },
    selectDropdownList: {
      default: '#FFFFFF',
      hover: '#C8EDFF',
      selected: '#007AFF',
    },
    sidebar: {
      account: {
        hover: '#F1F1F1',
      },
      default: '#FFFFFF',
      function: {
        hover: '#FFFACC',
        selected: '#FFF4A3',
      },
    },
    stepper: {
      default: '#F1F1F1',
      hover: '#EAEAEA',
    },
    table: {
      accent: '#FBFBFB',
      normal: '#FFFFFF',
      unread: '#007AFF',
    },
    tags: {
      default: '#EFFBFF',
      disabled: '#F1F1F1',
      hover: '#0049BF',
    },
    textArea: {
      default: '#FFFFFF',
      disabled: '#FBFBFB',
      error: '#FFF8F8',
    },
    toast: '#FFFFFF',
    toggle: {
      false: '#EAEAEA',
      true: '#007AFF',
    },
    windowArea: '#EAEAEA',
  },
  border: {
    alertMessageBox: '#FF3B30',
    avatar: {
      problem: '#DF3623',
    },
    badge: {
      progress: '#EAEAEA',
    },
    button: {
      secondary: {
        default: '#EAEAEA',
        disabled: '#EAEAEA',
        hover: '#EAEAEA',
        selected: '#0060DF',
      },
    },
    check: {
      container: '#EAEAEA',
      default: {
        false: '#AAAAAA',
      },
      disabled: {
        false: '#EAEAEA',
      },
    },
    checkContainer: {
      default: '#EAEAEA',
      disabled: '#EAEAEA',
      hover: '#007AFF',
    },
    circleRadioButton: {
      default: {
        false: '#AAAAAA',
        true: '#007AFF',
      },
      disabled: {
        false: '#EAEAEA',
        true: '#EAEAEA',
      },
    },
    default: '#EAEAEA',
    draggableInputImages: {
      default: '#EAEAEA',
      error: '#FF3B30',
      hover: '#007AFF',
      selected: '#0060DF',
    },
    draggableInputPdfs: {
      default: '#EAEAEA',
      error: '#FF3B30',
      hover: '#007AFF',
      selected: '#0060DF',
      uploaded: '#EAEAEA',
    },
    error: '#FF3B30',
    focus: '#0060DF',
    input: {
      default: '#EAEAEA',
      disabled: '#EAEAEA',
      error: '#FF3B30',
      hover: '#007AFF',
      selected: '#0060DF',
    },
    inputTime: {
      default: '#EAEAEA',
      disabled: '#EAEAEA',
      error: '#FF3B30',
      hover: '#007AFF',
      selected: '#0060DF',
    },
    messageBox: {
      error: '#FF3B30',
      information: '#EAEAEA',
      success: '#33D043',
      warning: '#FFD700',
    },
    segmentedControl: {
      default: '#EAEAEA',
      hover: '#EAEAEA',
      selected: '#0060DF',
    },
    selectDropdown: {
      default: '#EAEAEA',
      disabled: '#EAEAEA',
      error: '#FF3B30',
      hover: '#007AFF',
      selected: '#0060DF',
    },
    sidebar: {
      selected: '#FFD700',
    },
    sub: '#AAAAAA',
    tags: {
      default: '#007AFF',
      disabled: '#EAEAEA',
      hover: '#0049BF',
    },
    textArea: {
      default: '#EAEAEA',
      disabled: '#EAEAEA',
      error: '#FF3B30',
      hover: '#007AFF',
      selected: '#0060DF',
    },
  },
  brand: {
    black: '#000000',
    yellow: '#FFD700',
  },
  icon: {
    error: '#DF3623',
    sub: '#AAAAAA',
    success: '#21972F',
  },
  text: {
    errorMessage: '#DF3623',
    holiday: '#DF3623',
    link: {
      default: '#0060DF',
      hover: '#0049BF',
    },
    placeholder: '#AAAAAA',
    saturday: '#0060DF',
    sub: '#808080',
    successMessage: '#21972F',
    supportMessage: '#F29300',
  },
  textIconSet: {
    default: '#343434',
    disabled: '#AAAAAA',
    inverse: '#FFFFFF',
    selected: '#0060DF',
  },
} as const;

/** @deprecated グローバルトークンは直接参照しないでください */
export const globalTokens = {
  blue: {
    '100': '#A1DAFF',
    '200': '#79C1FF',
    '300': '#52A4FF',
    '400': '#298FFF',
    '500': '#007AFF',
    '600': '#006DEF',
    '700': '#0060DF',
    '800': '#0054CF',
    '900': '#0049BF',
    '025': '#EFFBFF',
    '050': '#C8EDFF',
  },
  gray: {
    '100': '#FBFBFB',
    '200': '#F1F1F1',
    '300': '#EAEAEA',
    '400': '#DBDBDB',
    '500': '#C7C7CC',
    '600': '#B7B8BC',
    '700': '#AAAAAA',
    '800': '#9A9A9A',
    '900': '#808080',
    '1000': '#6D6D6D',
    '1100': '#525252',
    '1200': '#484848',
    '1300': '#343434',
    '1400': '#1C1C1C',
    '1500': '#000000',
    '000': '#FFFFFF',
    '000_30': '#ffffff4d',
    '000_80': '#ffffffcc',
    '1500_20': '#00000033',
    '1500_50': '#00000080',
  },
  green: {
    '100': '#AEEFB5',
    '200': '#90E899',
    '300': '#71E07C',
    '400': '#52D860',
    '500': '#33D043',
    '600': '#22B431',
    '700': '#21972F',
    '800': '#22812D',
    '900': '#23712D',
    '025': '#ECFFEE',
    '050': '#CDF7D1',
  },
  red: {
    '100': '#FFB4B0',
    '200': '#FF958F',
    '300': '#FF766E',
    '400': '#FF584F',
    '500': '#FF3B30',
    '600': '#EF3829',
    '700': '#DF3623',
    '800': '#CF331E',
    '900': '#BF3118',
    '025': '#FFF8F8',
    '050': '#FFE1DF',
  },
  yellow: {
    '100': '#FFF4A3',
    '200': '#FFEE7A',
    '300': '#FFE752',
    '400': '#FFE029',
    '500': '#FFD700',
    '600': '#FCC700',
    '700': '#F9B700',
    '800': '#F5A500',
    '900': '#F29300',
    '025': '#FFFFEA',
    '050': '#FFFACC',
  },
} as const;
