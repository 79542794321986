import { useGTM } from '~/utils/GTM';

// NOTE: https://support.karte.io/post/ALVPd86B5a3m4Gjj6Q7hq
export const useKarteViewEvent = () => {
  const { sendToGTM } = useGTM();
  const sendKarteViewEvent = () => {
    return sendToGTM({ event: 'NAVIGATION_HISTORY_CHANGED' });
  };

  return sendKarteViewEvent;
};
