import React from 'react';
import { RecommendedBrowserLabels } from '~/libs/recommendBrowserMessage';
import { alignCss } from '~/styles/alignCss';
import * as styles from './index.styles';

export const UnsupportedBrowserPage: React.FC = () => {
  return (
    <div css={styles.unsupportedBrowser.container}>
      <div>
        <div>
          2023年10月10日をもちまして、株式会社タイミーが提供する企業用/店舗用管理画面は、Microsoft
          社製ブラウザ Internet Explorer のサポートを終了しました。
        </div>
        <div css={alignCss({ mt: 2 })}>
          2023年10月10日以降は、弊社が推奨する以下のブラウザをご利用ください。
        </div>
        <div css={alignCss({ mt: 2, ml: 4 })}>
          <ul css={styles.unsupportedBrowser.recommendBrowser}>
            {RecommendedBrowserLabels.map((browser, i) => (
              <li key={i}>{browser}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
