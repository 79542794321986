import { css } from '@emotion/react';
import { colors } from '~/styles/colors';
import { textCss } from '~/styles/textCss';

const height = 40;
const top = 100;

export const container = (args: { flagged: boolean }) => css`
  ${textCss({ size: 'xs', weight: 'bold' })}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: ${args.flagged ? colors.alert : colors.accent};
  color: ${colors.white};
  position: fixed;
  top: ${top}px;
  left: 0;
  width: ${(top + height) * 1.414}px;
  height: ${height}px;
  transform: rotate(-45deg);
  transform-origin: bottom left;
  cursor: pointer;
  opacity: 0.9;
  &:hover {
    opacity: 0.7;
  }
`;
