import React, { createContext, useContext, useState } from 'react';
import { PageReplaceNextLink, RedirectUrl } from '~/models/types/common';

export const Categories = {
  ACCOUNT: 'account',
  CLIENT: 'client',
  COMPANY: 'company',
  ADMIN: 'admin',
} as const;

type CategoryInfo = { category: typeof Categories[keyof typeof Categories] };

// 共通して必要な action だけ記述する
type ApiClient = {
  logout: () => Promise<RedirectUrl | PageReplaceNextLink>;
};

type CategoryContextState = {
  categoryInfo: CategoryInfo;
  apiClient: ApiClient;
};

type CategoryContextAction = {
  setCategoryContext: (categoryContext: CategoryContextState) => void;
};

export type CategoryContextType = {
  state: CategoryContextState | null;
  actions: CategoryContextAction;
};

const defaultValue: CategoryContextType = {
  state: null,
  actions: { setCategoryContext: () => {} },
};

const CategoryContext = createContext<CategoryContextType>(defaultValue);

export const CategoryContextProvider: React.FC<{
  children: React.ReactNode;
  defaultValueForTest?: CategoryContextState;
}> = (props) => {
  const [categoryContext, setCategoryContext] = useState<CategoryContextState | null>(
    props.defaultValueForTest || null
  );

  return (
    <CategoryContext.Provider
      value={{
        state: categoryContext,
        actions: { setCategoryContext },
      }}
    >
      {props.children}
    </CategoryContext.Provider>
  );
};

export const useCategoryContext = () => useContext(CategoryContext);
