import { IClientPathBuilder } from './index.type';

export type { IClientPathBuilder } from './index.type';

export const clientPathBuilder: IClientPathBuilder = (clientId: number) => ({
  home: () => `/clients/${clientId}`,
  contact: () => `/clients/${clientId}/contact`,
  feedback: () => `/clients/${clientId}/feedback`,
  notice: () => `/clients/${clientId}/notice`,
  detail: () => ({
    index: () => `/clients/${clientId}/detail`,
    phoneNumber: () => `/clients/${clientId}/detail/phone_number`,
  }),
  offers: () => ({
    list: () => `/clients/${clientId}/offers`,
    detail: (offerId: number) => `/clients/${clientId}/offers/${offerId}`,
    create: () => `/clients/${clientId}/offers/new`,
    copy: (offerId: number) => `/clients/${clientId}/offers/new?offer_id=${offerId}`,
    edit: (offerId: number) => `/clients/${clientId}/offers/${offerId}/edit`,
  }),
  offerings: () => ({
    list: () => `/clients/${clientId}/offerings`,
    bulkCreate: (baseOfferingId: number) =>
      `/clients/${clientId}/offerings/bulk_create?offeringId=${baseOfferingId}`,
    detail: (offeringId: number) => `/clients/${clientId}/offerings/${offeringId}`,
    userReject: (offeringId: number, userId: number) =>
      `/clients/${clientId}/offerings/${offeringId}/reject/${userId}`,
    new: (offerId: number) => `/clients/${clientId}/offers/${offerId}/offerings/new`,
    edit: (offerId: number, offeringId: number) =>
      `/clients/${clientId}/offers/${offerId}/offerings/${offeringId}/edit`,
    copy: (offerId: number, offeringId: number) =>
      `/clients/${clientId}/offers/${offerId}/offerings/new?offering_id=${offeringId}`,
    confirm: (offerId: number) => `/clients/${clientId}/offers/${offerId}/offerings/confirm`,
  }),
  reviews: () => ({
    client: () => `/clients/${clientId}/reviews/client`,
    users: () => `/clients/${clientId}/reviews/users`,
    offerings: (offeringId: number) => ({
      users: () => `/clients/${clientId}/offerings/${offeringId}/reviews/users`,
    }),
  }),
  fixPaymentRequests: () => `/clients/${clientId}/fix_payment_requests`,
  attendances: () => `/clients/${clientId}/attendances`,
  users: () => ({
    invitation: () => `/clients/${clientId}/users/invitation`,
    groups: () => `/clients/${clientId}/users/groups`,
    list: () => `/clients/${clientId}/users`,
    listWithType: (opt: { listType: number }) =>
      `/clients/${clientId}/users?list_type=${opt.listType}`,
    detail: (userId: number) => `/clients/${clientId}/users/${userId}`,
  }),
  statements: () => `/clients/${clientId}/statements`,
  chat: () => ({
    list: (keyword?: string) =>
      `/clients/${clientId}/chat_rooms${keyword ? `?keyword=${keyword}` : ''}`,
    chatRoom: (chatRoomId: string) => `/clients/${clientId}/chat_rooms/${chatRoomId}`,
  }),
});
