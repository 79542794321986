import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import { useProgressBar } from '~/hooks/config/useProgressBar';
import { useStyles } from '~/hooks/config/useStyles';
import { LayoutComponent, NextPageWithLayout } from '~/types/layout';
import { useWarnSelfXss } from '~/hooks/config/useWarnSelfXss';
import { useViewportTrimmer } from '~/hooks/config/useViewportTrimmer';
import { useConfigReactDatepicker } from '~/hooks/config/useConfigReactDatepicker';
import { useGoogleTagManager } from '~/hooks/config/useGoogleTagManager';
import { envManager } from '~/constants/envManager';
import { FlashMessagesProvider } from '~/hooks/context/useFlashMessages';
import { ToastMessagesProvider } from '~/hooks/context/useToastMessages';
import { CategoryContextProvider } from '~/hooks/context/useCategoryContext';
import { useCustomHead } from '~/hooks/config/useCustomHead';
import { useRouter } from 'next/router';
import { useChatbot } from '~/hooks/config/useChatbot';
import { useDevelopmentFeatureFlagSetter } from '~/hooks/config/useDevelopmentFeatureFlagSetter';
import { FeatureProvider } from '~/hooks/context/FeatureProvider';
import { SplittedPhrase } from '~/components/typographies/SplittedPhrase';
import { recommendBrowserMessage } from '~/libs/recommendBrowserMessage';
import { UnsupportedBrowserPage } from '~/components/pages/other/UnsupportedBrowserPage';
import { externalLinks } from '~/constants/externalLinks';

// polyfill
import 'core-js/features/object/from-entries';

// styles
import 'react-toastify/dist/ReactToastify.css';

// styles
import { global } from '~/styles/global';
import { reset } from '~/styles/reset';
import 'react-toastify/dist/ReactToastify.css';
import { MaintenancePage } from '~/components/pages/other/MaintenancePage';
import { getSingleActionLayout } from '~/components/pages/other/getSingleActionLayout';
import { useEnvironmentViewer } from '~/hooks/config/useEnvFlagViewer';
import { SWRConfig } from 'swr';
import { useBeforePopStateHandlers } from '~/hooks/config/useBeforePopStateHandlers';
import { ConfirmModalProvider } from '~/hooks/common/useConfirmModal';
import { useRouteChangeCompleteListener } from '~/hooks/config/useRouteChangeCompleteListener';

if (envManager.publicEnv.environment === 'test') {
  const MockServer = () => import('~/mocks/api/worker');
  MockServer();
}

const LayoutEmpty: LayoutComponent = (props) => <>{props.children}</>;

const Layout: React.FC<{
  children: React.ReactNode;
  layoutComponent: LayoutComponent | undefined;
}> = (props) => {
  const InnerLayout = props.layoutComponent || LayoutEmpty;
  return <InnerLayout>{props.children}</InnerLayout>;
};

const MainApp: React.FC<{
  appProps: AppProps;
}> = ({ appProps }) => {
  const router = useRouter();

  // custom layout
  // https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
  const Component: NextPageWithLayout = appProps.Component;

  // hooks
  useWarnSelfXss();
  useViewportTrimmer(375);
  useConfigReactDatepicker();
  useDevelopmentFeatureFlagSetter();
  useRouteChangeCompleteListener();

  const chatbot = useChatbot({
    show: !!router.asPath.match(/^\/clients\/\d+\/contact$/),
  });
  const customHead = useCustomHead({ title: Component.config?.pageTitle });
  const tagManager = useGoogleTagManager({
    gtmId: envManager.publicEnv.gtmId,
    gtmAuth: envManager.publicEnv.gtmAuth,
    gtmPreview: envManager.publicEnv.gtmPreview,
  });
  const progressBar = useProgressBar();
  const globalStyles = useStyles([global, reset]);
  const environmentViewer = useEnvironmentViewer();
  useBeforePopStateHandlers();

  // NOTE: https://app-new.taimee.co.jpでアクセスされた場合、app-newのログイン画面へリダイレクト
  // NOTE: 本来、monorepo等で解決すべきだが、React/Nextアップグレード前の一次的対処として実装。
  useEffect(() => {
    if (envManager.publicEnv.targetDomain === 'client' && router.asPath.match(/^\/$/))
      router.replace('/login');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.StrictMode>
      <SWRConfig value={{ dedupingInterval: 10_000 }}>
        <CategoryContextProvider>
          <ToastMessagesProvider>
            <FlashMessagesProvider pathname={router.pathname}>
              <FeatureProvider router={router}>
                <ConfirmModalProvider>
                  <Layout layoutComponent={Component.Layout}>
                    {/* NOTE: dev環境Safariにてアクセスした場合、TypeErrorが表示されるためこの位置でレンダリング*/}
                    {environmentViewer.render()}
                    <Component {...appProps.pageProps} />
                  </Layout>
                </ConfirmModalProvider>
              </FeatureProvider>
            </FlashMessagesProvider>
          </ToastMessagesProvider>
        </CategoryContextProvider>
      </SWRConfig>

      {customHead.render()}
      {progressBar.renderStyles()}
      {globalStyles.renderStyles()}
      {tagManager.render()}
      {chatbot.render()}
    </React.StrictMode>
  );
};

const UnderMaintenanceApp: React.FC = () => {
  const globalStyles = useStyles([global, reset]);
  const Layout = getSingleActionLayout();

  return (
    <>
      <Layout>
        <MaintenancePage />
      </Layout>
      {globalStyles.renderStyles()}
    </>
  );
};

const UsingUnsupportedBrowserApp: React.FC = () => {
  const customHead = useCustomHead({ title: 'ご利用中のブラウザのサポート終了のお知らせ' });
  const globalStyles = useStyles([global, reset]);

  const Layout = getSingleActionLayout({
    getLayoutContents: () => {
      return {
        showHeaderLogo: true,
        hiddenFooterLogo: true,
        title: (
          <SplittedPhrase
            words={[
              'Internet Explorer からは、',
              '企業用/店舗用',
              '管理画面に',
              'アクセスできません。',
            ]}
          />
        ),
        footerLink: {
          title: 'ログインについてのお問い合わせはこちら',
          href: externalLinks.inquiryAboutLogin,
        },
        themeColorBg: true,
        titleColorAlert: true,
      };
    },
  });

  return (
    <>
      <Layout>
        <UnsupportedBrowserPage />
      </Layout>

      {customHead.render()}
      {globalStyles.renderStyles()}
    </>
  );
};

const CustomApp = (appProps: AppProps) => {
  if (envManager.publicEnv.applicationMode === 'under_maintenance') return <UnderMaintenanceApp />;

  const browserStatus = recommendBrowserMessage();
  if (!browserStatus.usingRecommend && browserStatus.usingBlockBrowser) {
    return <UsingUnsupportedBrowserApp />;
  }

  return <MainApp appProps={appProps} />;
};

export default CustomApp;
